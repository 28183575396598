<script>
  import { createEventDispatcher } from "svelte";
  export let className;
  export let width=80;

  const dispatch = createEventDispatcher();
  const handleClick = () => {
    dispatch("handleClick");
  };
</script>

<button on:click={handleClick} class={`btn ${className} `} style="width: {`${width}`}%;" >
  <slot />
</button>

<style>
  .btn {
    padding: 15px 33px;
    gap: 20px;
    background: #2d334a;
    border-radius: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn.next {
    background: #2f5af3;
    width: 80%;
  }

  .btn:active {
    transform: scale(0.98);
  }

  .btn:focus {
    outline: 0;
  }

  .btn:disabled {
    display: none;
  }
  @media (max-width: 640px) {
    .btn {
      padding: 10px 20px;
    }
  }
</style>
