<script>
  import { Circle2 } from "svelte-loading-spinners";
</script>

<div class="loader-container">
  <hr />
  <div class="loader">
    <Circle2 size="100" color="#2f5af3" unit="px" duration="1.5s" />
  </div>
</div>

<style>
  .loader {
    margin: 150px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes fill {
    0% {
      transform: rotate(135deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
</style>
