export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const ValidateEmail = (email) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    console.log("Valid Email");
    return true;
  } else {
    console.log("Invalid Email");
    return false;
  }
};

export const validatePhoneNumber = (phone) => {
  // let reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  let reg = /^[0-9 ]+$/;

  return reg.test(phone);
};

export const validateString = (str) => {
  return /^[A-Za-z0-9 ]+$/.test(str);
};
