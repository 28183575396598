<div class="banks-ethiopia-form-card">
  <slot />
</div>

<style>
  .banks-ethiopia-form-card {
    padding: 36px 50px 36px 36px;
    gap: 10px;
    background: #ffffff;
    /* box-shadow: 0px; */
    box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.06), -3px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    width: 100%;
    z-index: 10;
  }
  @media (max-width: 640px) {
    .banks-ethiopia-form-card {
      width: 100%;
      padding: 20px;
    }
  }
</style>
