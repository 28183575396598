<script>
  import { createEventDispatcher } from "svelte";
  import Button from "../shared/Button.svelte";
  import BanksEthiopiaStore from "../store";
  const dispatch = createEventDispatcher();
  export let current_active;

  const handleClick = () => {
    current_active = current_active + 1;
    let currentValue = 0;
    let formValue = {};
    dispatch("steps", { currentValue, formValue });
  };
  BanksEthiopiaStore.update(value=>{
    return {}
  })
</script>

<div class="thank-you-container">
  <div class="thank-you-header">
    <svg
      width="86"
      height="87"
      viewBox="0 0 86 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="42.9999"
        cy="43.715"
        r="28.6667"
        stroke="#00FF29"
        stroke-width="4"
      />
      <path
        d="M32.9666 45.1483L38.6999 50.8817L53.0332 36.5483"
        stroke="#00FF29"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="complete">
    <h3 class="complete-title">Thank you for your Application</h3>
    <div class="complete-recommend-text">
      <h4>What happens now?</h4>
      <p>
        We have now taken a credit report on you and your application has been
        sent to our lenders. We will contact you soon.
      </p>
    </div>
  </div>

  <div class="step-button">
    <!-- <Button on:handleClick={handleClick} className={'next'} width={100}>
      <span>See Offering</span>
      <i class="fa fa-arrow-right"></i>
    </Button> -->
  </div>
</div>

<style>
  .thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* width: 450px; */
  }
  .complete {
    margin-top: 40px;
    align-items: center;
    text-align: center;
  }
  .complete-recommend-text {
    box-sizing: border-box;
    width: 450px;
    background: rgba(222, 228, 238, 0.25);
    border: 1px solid #dbe0e9;
    text-align: left;
    padding: 15px;
    margin: 50px auto;
  }
  .complete-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c274c;
  }
  .complete-recommend-text h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: rgba(28, 39, 76, 0.9);
  }
  .complete-recommend-text p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: rgba(28, 39, 76, 0.9);
  }

  @media (max-width: 640px) {
    .complete-recommend-text {
      width: 100%;
    }
    .complete-title {
      font-size: 22px;
    }
  }
</style>
