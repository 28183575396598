<script>
  import { createEventDispatcher } from "svelte";
  import Button from "../shared/Button.svelte";
  import BanksEthiopiaStore from "../store";
  import { countries } from "../assets/AllCountry";
  import { set_attributes } from "svelte/internal";

  const dispatch = createEventDispatcher();
  export let current_active;

  const customCurrency = {
    USD: "USD",
    EUR: "EUR",
    GBP: "GBP",
    ETB: "ETB",
  };

  const formValue = {
    service_type: "Mortgage Loan",
    country: "United States of America",
    currency: "USD",
    loan_amount: 100000000, //in birr
    loan_term: 1, //1 year default
  };

  BanksEthiopiaStore.subscribe((value) => {
    formValue.service_type = value.service_type
      ? value.service_type == "Create Account"
        ? "Diaspora House Loan"
        : "Diaspora Car Loan"
      : "Diaspora House Loan";
    if (formValue.service_type != "Create Account") {
      formValue.country = value.country
        ? value.country
        : "United States of America";
      formValue.currency = value.currency ? value.currency : "USD";
      formValue.loan_amount = value.loan_amount ? value.loan_amount : 100000;
      formValue.loan_term = value.loan_term ? value.loan_term : 1;
    }
  });

  // convert number to international currency system
  const convertToInternationalCurrencySystem = (number) => {
    return Math.abs(Number(number)) >= 1.0e9
      ? (Math.abs(Number(number)) / 1.0e9).toFixed(2) + "B"
      : Math.abs(Number(number)) >= 1.0e6
      ? (Math.abs(Number(number)) / 1.0e6).toFixed(2) + "M"
      : Math.abs(Number(number)) >= 1.0e3
      ? (Math.abs(Number(number)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(number)).toFixed(2);
  };

  const calculteApproximateCost = (loan) => {
    let interest_rate = 14;
    let loan_amount = formValue.loan_amount;
    let loan_term = formValue.loan_term;
    let total_interest = (loan_amount * interest_rate) / 100;
    let total_cost = loan_amount + total_interest;
    let per_month = total_cost / (loan_term * 12);
    return convertToInternationalCurrencySystem(per_month);
  };

  const handleBtnClick = (e) => {
    if (e == "minus") {
      if (formValue.loan_term > 1) {
        formValue.loan_term--;
      }
    } else {
      formValue.loan_term++;
    }
    approximate_cost = calculteApproximateCost();
  };

  const handleClick = () => {
    current_active = current_active + 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
    BanksEthiopiaStore.update((value) => {
      value.country = formValue.country;
      value.currency = formValue.currency;
      value.loan_term = formValue.loan_term;
      value.loan_amount = formValue.loan_amount;
      value.service_type = formValue.service_type;
      return value;
    });
  };

  let currentServiceValue = 1;
  let max = 100000000;
  let approximate_cost = 10000000;

  const handleRadio = (e) => {
    currentServiceValue = e.currentTarget.value;
    if (e.currentTarget.value == 1) {
      max = 100000000;
      formValue.service_type = "Mortgage Loan";
    }
    if (e.currentTarget.value == 2) {
      max = 50000000;
      formValue.service_type = "Vehicle Loan";
    }
    if (e.currentTarget.value == 3) {
      formValue.service_type = "Create Account";
      current_active = current_active + 1;
      let currentValue = current_active;
      dispatch("steps", { currentValue, formValue });
      BanksEthiopiaStore.update((value) => {
        value.service_type = formValue.service_type;
        if (formValue.service_type != "Create Account") {
          value.country = formValue.country;
          value.currency = formValue.currency;
          value.loan_term = formValue.loan_term;
          value.loan_amount = formValue.loan_amount;
        }
        return value;
      });
    }
  };
  // render options
  const renderOptions = () => {
    let SELECT = document.getElementById("select");
    let SELECT_MODAL = document.getElementById("select-modal");
    countries.forEach((item) => {
      let OPT = document.createElement("OPTION");
      OPT.setAttribute("value", item.name);
      OPT.appendChild(document.createTextNode(item.name));
      SELECT.appendChild(OPT);
    });
    if (SELECT_MODAL) {
      countries.forEach((item) => {
        let OPT_MODAL = document.createElement("OPTION");
        OPT_MODAL.setAttribute("value", item.name);
        OPT_MODAL.appendChild(document.createTextNode(item.name));
        SELECT_MODAL.appendChild(OPT_MODAL);
      });

      SELECT_MODAL.onchange = function (e) {
        giveSelection(e);
      };
    }

    SELECT.onchange = function (e) {
      giveSelection(e);
    };
  };
  // interlate country with its crosponding currency
  const giveSelection = (e) => {
    let country = countries.find((item) => item.name == e.target.value);
    let currency = country.currency.code;
    formValue.country = e.target.value;

    if (
      currency === "USD" ||
      currency === "EUR" ||
      currency === "GBP" ||
      currency === "ETB"
    ) {
      formValue.currency = currency;
    } else {
      formValue.currency = "USD";
    }
  };
  let select = document.getElementsByClassName("select");
  let select_modal = document.getElementsByClassName("pop-select");
  document.body.addEventListener("mouseleave", function () {
    // select_modal[0].style.display = "none";
    if (localStorage.getItem("modal_shown") == "true") {
      if (select_modal) {
        select_modal[1].style.display = "block";
      }
      select[1].style.display = "none";
    }
  });
</script>

<div class="service-type-container">
  <!-- <h3>Diaspora Serivce</h3> -->
  <div class="service-type-form-container">
    <label class="service-container"
      ><span>Mortgage Loan </span>
      <input
        type="radio"
        checked="checked"
        name="service"
        on:change={handleRadio}
        value="1"
      />
      <span class="checkmark" />
    </label>
    <label class="service-container">
      <span>Vehicle Loan </span>
      <input type="radio" name="service" on:change={handleRadio} value="2" />
      <span class="checkmark" />
    </label>
    <label class="service-container">
      <span>Create Account </span>
      <input type="radio" name="service" on:change={handleRadio} value="3" />
      <span class="checkmark" />
    </label>
  </div>
  <hr />
  <div class="country-currency">
    <div class="country-container">
      <label for="country">Country</label>
      <select
        name="country"
        aria-label="country"
        id="select"
        class="select"
        on:focus={() => renderOptions()}
      >
        <option value="" selected disabled>Select Country</option>
      </select>
      <select
        name="country"
        aria-label="country"
        id="pop-select"
        class="pop-select"
        on:focus={() => renderOptions()}
        style="display: none;"
      >
        <option value="" selected disabled>Select Country</option>
      </select>
    </div>

    <div class="currency-container">
      <label for="currency"
        >Currency<span class="currency-info">
          <!-- <i class="fa fa-info-circle" aria-hidden="true" /> -->
          <div class="currency-info-body">
            <div class="currency-info-triangle" />
            Calculation example: Annuity loan 12 years. Effective annual interest
            rate 5.69%
          </div>
        </span></label
      >

      <select
        name="currency"
        class="currency"
        id="currency"
        bind:value={formValue.currency}
      >
        <!-- {#each countries as { currency }}
          <option value={currency.code}>{currency.code}</option>
        {/each} -->
        {#each Object.entries(customCurrency) as [key, value]}
          <option value={key}>{key}</option>
        {/each}
      </select>
    </div>
  </div>

  <div class="loan-amount-container">
    <label for="loan-amount">Select loan amount</label>
    <div class="loan-input-lable">
      <input
        type="number"
        name="loan_amount"
        id="loan-amount"
        {max}
        bind:value={formValue.loan_amount}
      />
      <div class="loan-amount-lable">{formValue.currency}</div>
    </div>
  </div>
  <div class="loan-amount-slider-container">
    <input
      aria-label="loan amount"
      class="loan-amount-slider"
      type="range"
      min="10000"
      {max}
      step="5000"
      on:change={calculteApproximateCost}
      bind:value={formValue.loan_amount}
    />
    <div class="min-max-loan">
      <p>10K</p>
      <p>{convertToInternationalCurrencySystem(max)}</p>
    </div>
  </div>

  <div class="loan-term-container">
    <p class="loan__term_label">Select Loan Term</p>
    <div class="loan-term-btns">
      <button class="loan-term-btn" on:click={() => handleBtnClick("minus")}
        >-</button
      >
      <input
        id="year-term"
        aria-label="year-term"
        type="number"
        bind:value={formValue.loan_term}
      />
      <div class="year-label-design">Year</div>
      <button class="loan-term-btn" on:click={() => handleBtnClick("plus")}
        >+</button
      >
    </div>
  </div>
  <div class="approximate-cost">
    <p>Approximate Cost</p>
    <span>
      {calculteApproximateCost(formValue.loan_amount)}
      {formValue.currency} / month
    </span>
  </div>

  <div class="step-button">
    <Button on:handleClick={handleClick} className={"next"} width={80}>
      <span>Start Application</span>
      <i class="fa fa-arrow-right" />
    </Button>
  </div>
  <p class="t-c">
    <!-- Calculation example: Annuity loan 12 years. Effective annual interest rate
    5.69%. A loan of ETB 200,000 then costs ETB 1,905/month (144 installments),
    i.e. a total of ETB 274,411. No take-off/flying fee. 5.55% nominal interest
    rate (variable interest rate, set individually based on your conditions).
    The application will be sent to the lenders that best match your profile. -->
  </p>
</div>

<style>
  .currency-info-body {
    display: none;
  }
  .currency-info {
    color: #000;
    cursor: pointer;
    position: relative;
  }
  /* .currency-info i {
    font-size: 14px;
    margin-left: 10px;
  } */
  .currency-info:hover .currency-info-body {
    display: block;
    position: absolute;
    top: 26px;
    left: -256px;
    background: #ffffff;
    width: 300px;
    height: auto;
    font-size: 14px;
    padding: 10px;
    box-shadow: 3px 4px 12px rgb(0 0 0 / 6%), -3px 0px 12px rgb(0 0 0 / 6%);
    border-radius: 0px 0px 10px 10px;
  }
  .currency-info-body .currency-info-triangle {
    position: absolute;
    top: -10px;
    left: 90%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #ffffff;
  }
  .service-container {
    display: block;
    position: relative;
    padding-left: 29px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .service-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  .service-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .service-container input:checked ~ .checkmark {
    background-color: #2f5af3;
  }
  .service-container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .service-container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  .service-type-form-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .country-currency {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .country-currency select {
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    width: 100%;
  }

  .country-currency .country-container {
    width: 70%;
  }
  .country-currency .currency-container {
    width: 25%;
  }
  .currency-container select {
    width: 100%;
  }

  /* Loan amount  */
  .loan-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px 20px 0px;
  }
  .loan__term_label {
    font-size: 20px;
  }
  .loan-input-lable {
    display: flex;
  }
  .loan-amount-container label {
    /* font-family: "Quicksand"; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1c274c;
  }
  .loan-amount-container input {
    box-sizing: border-box;
    border: 1px solid rgba(28, 39, 76, 0.4);
    border-radius: 30px 0px 0px 30px;
    width: 150px;
    /* height: 50px; */
    background-color: inherit;
    padding: 9px 30px;
    height: 45px;
  }
  .loan-amount-lable {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    background-color: #1c274c;
    margin-left: 0px;
    color: #fff;
    border-radius: 0px 30px 30px 0px;
    width: 60px;
    padding: 15px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
  }

  /* Slider */
  .loan-amount-slider-container {
    width: 100%;
  }
  .loan-amount-slider-container input[type="range"] {
    width: 100%;
  }
  .min-max-loan {
    display: flex;
    justify-content: space-between;
    margin-top: -12px;
    /* font-family: 'Quicksand'; */
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;

    color: rgba(28, 39, 76, 0.9);
  }

  /* Loan term */
  .loan-term-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px;
  }
  .loan-term-btns {
    display: flex;
    align-items: center;
  }
  .loan-term-btns button {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: #1c274c;
    color: #fff;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loan-term-btns button:focus {
    outline: none;
  }
  .loan-term-btns input {
    height: 45px;
    width: 106px;
    margin-left: 10px;
    border-radius: 30px 0px 0px 30px;
    padding: 10px 20px;
  }
  .year-label-design {
    height: 45px;
    width: 64px;
    background-color: #1c274c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 7px;
    border-radius: 0px 30px 30px 0px;
  }
  .approximate-cost {
    width: 100%;
    height: 60px;
    background-color: #f2f5fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    background: #f2f5fa;
    /* Text-10% */
    border: 1px solid rgba(28, 39, 76, 0.1);
  }

  .approximate-cost p {
    /* font-family: 'Quicksand'; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 1px; */
    /* identical to box height */

    /* Text-80% */

    color: rgba(28, 39, 76, 0.8);
    margin-top: -5px;
  }
  .approximate-cost span {
    /* font-family: 'Quicksand'; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 4px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Dark Blue-Text */
    color: #1c274c;
    margin-top: -16px;
    text-transform: capitalize;
  }

  .t-c {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: rgba(28, 39, 76, 0.8);
  }

  @media (max-width: 640px) {
    .service-container {
      font-size: 12px;
      color: #1c274c;
    }
    .checkmark {
      top: 0px;
    }
    .country-currency .country-container {
      width: 65%;
    }
    .country-currency .currency-container {
      width: 32%;
    }
    .currency-container select,
    .country-container select {
      width: 100%;
    }
    .loan-amount-container {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
    .loan-amount-container input {
      width: 76%;
    }
    .loan-amount-lable {
      width: 65px;
    }

    .loan-term-container {
      flex-direction: column;
      align-items: start;
    }
    .loan-term-btns {
      display: flex;
      align-items: center;
    }
    .loan-term-btns button {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: #1c274c;
      color: #fff;
    }
    .loan-term-btns input {
      height: 45px;
      margin-left: 10px;
      border-radius: 30px 0px 0px 30px;
      padding: 0px 20px;
      width: 55%;
    }
    .year-label-design {
      height: 45px;
      width: 70px;
      background-color: #1c274c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 10px;
      margin-bottom: 7px;
      border-radius: 0px 30px 30px 0px;
    }

    .service-container .checkmark:after {
      top: 5px;
      left: 5px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: white;
    }
    .service-container {
      padding-left: 20px;
      margin-bottom: 5px;
    }
    .checkmark {
      height: 17px;
      width: 17px;
    }
  }
</style>
