<script>
  export let title;
  export let next;
</script>

<div class="progress-bar-title-container">
  <h3 class="progress-bar-title">{title}</h3>
  <p class="progress-bar-next">Next: {next}</p>
</div>

<style>
  .progress-bar-title-container {
    margin-top: 8px;
  }
  .progress-bar-title-container .progress-bar-title {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    /* Dark Blue-Text */

    color: #1c274c;
  }
  .progress-bar-title-container .progress-bar-next {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #1c274c;
    margin-top: -15px;
  }
</style>