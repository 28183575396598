<script>
  import { createEventDispatcher } from "svelte";
  import Button from "../shared/Button.svelte";
  import Header from "../shared/Header.svelte";
  import BanksEthiopiaStore from "../store";
  import { countries } from "../assets/AllCountry";
  import {
    ValidateEmail,
    isValidUrl,
    validatePhoneNumber,
  } from "../utils/CheckValidity";

  const dispatch = createEventDispatcher();
  export let current_active;
  let validation = false;
  let createAccount;

  // subscribe the stor
  const formValue = {
    email: "",
    phone: "",
    ISO: "+1",
    civil_status: "Single",
    children_under_age: 0,
    co_applicants: false,

    co_applicant_email: "",
    co_applicant_phone: "",
    co_applicant_iso_code: "+1",
    co_applicant_civil_status: "Single",
    co_applicant_children_under_age: 0,
  };

  BanksEthiopiaStore.subscribe((value) => {
    createAccount = value.service_type;
    formValue.email = value.email ? value.email : "";
    formValue.phone = value.phone ? value.phone : "";
    formValue.ISO = value.ISO ? value.ISO : "+1";
    formValue.civil_status = value.civil_status;
    formValue.children_under_age = value.children_under_age
      ? value.children_under_age
      : 0;
    formValue.co_applicants = value.co_applicants ? value.co_applicants : false;

    formValue.co_applicant_email = value.co_applicant_email
      ? value.co_applicant_email
      : "";
    formValue.co_applicant_phone = value.co_applicant_phone
      ? value.co_applicant_phone
      : "";
    formValue.co_applicant_iso_code = value.co_applicant_iso_code
      ? value.co_applicant_iso_code
      : "+1";
    formValue.co_applicant_civil_status = value.co_applicant_civil_status
      ? value.co_applicant_civil_status
      : "Single";
    formValue.co_applicant_children_under_age =
      value.co_applicant_children_under_age
        ? value.co_applicant_children_under_age
        : 0;
  });
  const errors = {
    email: "",
    phone: "",
    civil_status: "",
    children_under_age: "",

    co_applicant_email: "",
    co_applicant_phone: "",
    co_applicant_civil_status: "",
    co_applicant_children_under_age: "",
  };
  const civilStatus = [
    "Single",
    "Married",
    "Divorced",
    "Widowed",
    "Separated",
    "Other",
  ];

  const handleClick = () => {
    validation = true;

    if (formValue.email === "") {
      errors.email = "Email is required";
      validation = false;
    } else {
      if (ValidateEmail(formValue.email)) {
        errors.email = "";
      } else {
        errors.email = "Invalid Email";
        validation = false;
      }
    }
    if (formValue.phone === "") {
      errors.phone = "Phone is required";
      validation = false;
    } else {
      if (!validatePhoneNumber(formValue.phone)) {
        errors.phone = "Phone is invalid";
        validation = false;
      } else {
        errors.phone = "";
      }
    }
    if (formValue.civil_status === "") {
      errors.civil_status = "Civil Status is required";
      validation = false;
    } else {
      errors.civil_status = "";
    }
    if (formValue.children_under_age < 0) {
      errors.children_under_age = "Children under age is required";
      validation = false;
    } else {
      if (formValue.children_under_age > 10) {
        errors.children_under_age = "Children under age must be less than 10";
        validation = false;
      } else {
        errors.children_under_age = "";
      }
    }

    if (formValue.co_applicants) {
      if (formValue.co_applicant_email === "") {
        errors.co_applicant_email = "Email is required";
        validation = false;
      } else {
        errors.co_applicant_email = "";
      }

      if (formValue.co_applicant_phone === "") {
        errors.co_applicant_phone = "Phone is required";
        validation = false;
      } else {
        errors.co_applicant_phone = "";
      }

      if (formValue.co_applicant_civil_status === "") {
        errors.co_applicant_civil_status = "Civil Status is required";
        validation = false;
      } else {
        errors.co_applicant_civil_status = "";
      }

      if (formValue.co_applicant_children_under_age < 0) {
        errors.co_applicant_children_under_age =
          "Children under age is required";
        validation = false;
      } else {
        if (formValue.co_applicant_children_under_age > 10) {
          errors.co_applicant_children_under_age =
            "Children under age must be less than 10";
          validation = false;
        } else {
          errors.co_applicant_children_under_age = "";
        }
      }
    }

    if (validation === false) {
      return;
    }

    current_active = current_active + 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
    BanksEthiopiaStore.update((value) => {
      // value.name = formValue.name;
      value.email = formValue.email;
      value.phone = formValue.phone;
      value.ISO = `${formValue.ISO}`;
      value.civil_status = formValue.civil_status;
      value.children_under_age = formValue.children_under_age;

      value.co_applicant_email = formValue.co_applicant_email;
      value.co_applicant_phone = formValue.co_applicant_phone;
      value.co_applicant_iso_code = `${formValue.co_applicant_iso_code}`;
      value.co_applicant_civil_status = formValue.co_applicant_civil_status;
      value.co_applicant_children_under_age =
        formValue.co_applicant_children_under_age;
      return value;
    });
  };
  const handleBack = () => {
    current_active = current_active - 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
  };
  let co_applicant = false;
  const addCoApplicant = () => {
    if (co_applicant == true) {
      co_applicant = false;
    } else {
      co_applicant = true;
    }
  };
</script>

<div>
  <div class="progerss-card-header">
    <div class="circle-wrap">
      <div class="circle">
        <div class="mask half">
          <div class="fill" />
        </div>
        <div class="mask full">
          <div class="fill" />
        </div>
        <div class="inside-circle">
          {#if createAccount == "Create Account"}
            1/4
          {:else}
            2/5
          {/if}
        </div>
      </div>
    </div>
    <Header title={"Personal Data"} next={"Housing & Work"} />
  </div>
  <hr />
  <div class="personal-data-container">
    <div class="personal-data">
      <label for="email">Email Address</label>
      <input type="text" name="email" id="email" bind:value={formValue.email} />
      <p class="error">{errors.email}</p>
      <label for="phone">Phone Number</label>
      <div class="phone-withcountrycode">
        <select name="phone" class="phone" id="" bind:value={formValue.ISO}>
          <option value="+1">+1</option>
          {#each countries as { dialling_code }}
            {#if dialling_code != "+1"}
              <option value={dialling_code}>{dialling_code}</option>
            {/if}
          {/each}
        </select>
        <input
          type="text"
          name="phone"
          id="phone"
          bind:value={formValue.phone}
        />
      </div>
      <p class="error">{errors.phone}</p>
      <div class="civil-child">
        <div class="civil-status-container">
          <label for="civil_status">Civil Status</label>
          <select
            name="civil_status"
            id="civil_status"
            bind:value={formValue.civil_status}
          >
            {#each civilStatus as status}
              <option value={status}>{status}</option>
            {/each}
          </select>
          <p class="error">{errors.civil_status}</p>
        </div>
        <div class="child-under-container">
          <label for="children_under_age">Children under 21 of age</label>
          <input
            type="number"
            max="10"
            name="children_under_age"
            id="children_under_age"
            bind:value={formValue.children_under_age}
          />
          <p class="error">{errors.children_under_age}</p>
        </div>
      </div>
      {#if createAccount != "Create Account"}
        <div class="add-co-applicant">
          <button on:click={() => addCoApplicant()}>Add Co-Applicant</button>
        </div>
      {/if}
    </div>
    {#if co_applicant}
      <hr />
      <div class="personal-data">
        <h3>Your fellow applicant</h3>
        <label for="email">Email Address</label>
        <input
          type="text"
          name="co_applicant_email"
          id="co_applicant_email"
          bind:value={formValue.co_applicant_email}
        />
        <p class="error">{errors.co_applicant_email}</p>
        <label for="phone">Phone Number</label>
        <div class="phone-withcountrycode">
          <select
            name="phone"
            class="phone"
            id=""
            bind:value={formValue.co_applicant_iso_code}
          >
            {#each countries as { dialling_code }}
              <option value={dialling_code}>{dialling_code}</option>
            {/each}
          </select>
          <input
            type="text"
            name="phone"
            id="phone"
            bind:value={formValue.phone}
          />
        </div>
        <p class="error">{errors.co_applicant_phone}</p>
        <div class="civil-child">
          <div class="civil-status-container">
            <label for="civil_status">Civil Status</label>
            <select
              name="co_applicant_civil_status"
              id="co_applicant_civil_status"
              bind:value={formValue.co_applicant_civil_status}
            >
              {#each civilStatus as status}
                <option value={status}>{status}</option>
              {/each}
            </select>
            <p class="error">{errors.co_applicant_civil_status}</p>
          </div>
          <div class="child-under-container">
            <label for="children_under_age">Children under 21 of age</label>
            <input
              type="number"
              name="co_applicant_children_under_age"
              id="co_applicant_children_under_age"
              bind:value={formValue.co_applicant_children_under_age}
            />
            <p class="error">{errors.co_applicant_children_under_age}</p>
          </div>
        </div>
      </div>
    {/if}
  </div>

  <div class="step-button">
    <Button on:handleClick={handleBack} className={"prev"} width={35}>
      <i class="fa fa-arrow-left" />
      <span> Back </span>
    </Button>
    <Button on:handleClick={handleClick} className={"next"} width={80}>
      <span>Continue</span>
      <i class="fa fa-arrow-right" />
    </Button>
  </div>
</div>

<style>
  .phone-withcountrycode {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .phone-withcountrycode select {
    width: 20%;
    border-radius: 5px 0px 0px 5px !important;
    padding: 0px !important;
  }
  .phone-withcountrycode input {
    width: 20%;
    border-radius: 0px 5px 5px 0px !important;
    margin-left: -2px;
  }
  .add-co-applicant {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #1c274c;
  }
  .circle-wrap {
    width: 64px;
    height: 64px;
    background: #e7f3e9;
    border-radius: 50%;
    border: 0px solid #cdcbd0;
  }
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
  }

  .mask .fill {
    clip: rect(0px, 32px, 64px, 0px);
    background-color: #00ff29;
  }

  /*  */
  .circle-wrap .circle .mask {
    clip: rect(0px, 64px, 64px, 32px);
  }

  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 0.5s;
    transform: rotate(90deg);
  }

  @keyframes fill {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  .circle-wrap .inside-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    line-height: 28px;
    text-align: center;
    margin-top: 7px;
    margin-left: 7px;
    color: #1c274c;
    position: absolute;
    z-index: 100;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Personal data container */
  .personal-data input,
  select {
    border: 0.5px solid rgba(28, 39, 76, 0.4);
    border-radius: 5px;
    width: 100%;
    height: 48px;
    padding: 0px 15px;
    /* margin-bottom: 18px; */
    background-color: inherit;
  }
  .personal-data label {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */

    /* Dark Blue-Text */

    color: #1c274c;
  }
  .civil-child {
    display: flex;
    justify-content: space-between;
  }
  .civil-child .civil-status-container,
  .civil-child .child-under-container {
    width: 45%;
  }
  .error {
    color: #d91b42;
    font-size: 12px;
    margin-top: 4px;
  }
  @media (max-width: 640px) {
    .phone-withcountrycode select {
      width: 30%;
      padding: 0px;
    }
    .civil-child {
      flex-direction: column;
    }
    .civil-child .civil-status-container,
    .civil-child .child-under-container {
      width: 100%;
    }
  }
</style>
