<script>
  import Complete from "./components/Complete.svelte";
  import FellowApplicant from "./components/FellowApplicant.svelte";
  import HousingWork from "./components/HousingWork.svelte";
  import PersonalData from "./components/PersonalData.svelte";
  import Service from "./components/Service.svelte";
  import ThankYou from "./components/ThankYou.svelte";
  import Card from "./shared/Card.svelte";
  import BanksEthiopiaStore from "./store";
  import axios from "axios";
  import Loader from "./components/Loader.svelte";
  import { ENV_OBJECT } from "./utils/env";
  export let current_active = 0;

  let store = [];
  let thanksPage = false;
  const handleSteps = (e) => {
    const { currentValue, formValue } = e.detail;
    if (currentValue == 4) {
      submitForm(e.detail);
    }
    current_active = currentValue;
  };

  let current_page = window.location.pathname;
  let page_source = '';
  if(current_page){
    let splits = current_page.split('/');
    page_source = splits[splits.length - 2];
    page_source = page_source.replace(/-/g, ' ');
    page_source = page_source.charAt(0).toUpperCase() + page_source.slice(1);
  }
  
  const submitForm = (active) => {
    let unsubscribe = BanksEthiopiaStore.subscribe(async (value) => {
      const res =await axios.get('https://api.ipify.org/?format=json');
      value.ip= res.data.ip;
      value.page_source = page_source;
      // console.log(value);
      await axios.post(ENV_OBJECT.URL, value).then((res) => {
        thanksPage = true;
        localStorage.setItem('modal_shown', 'false');
      });
    });
    unsubscribe();
    current_active = active;
  };
</script>

<main>
  <Card>
    <!-- <Loader /> -->
    {#if current_active == 0}
      <Service {current_active} on:steps={handleSteps} />
    {:else if current_active == 1}
      <PersonalData {current_active} on:steps={handleSteps} />
    {:else if current_active == 2}
      <HousingWork {current_active} on:steps={handleSteps} />
    {:else if current_active == 3}
      <Complete {current_active} on:steps={handleSteps} />
    {:else if thanksPage}
      <ThankYou on:steps={handleSteps} />
    {:else}
      <Loader />
    {/if}
  </Card>
</main>

<style>
  main {
    display: flex;
    justify-content: center;
  }
</style>
