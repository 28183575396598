<script>
  import { createEventDispatcher } from "svelte";
  import Button from "../shared/Button.svelte";
  import Header from "../shared/Header.svelte";
  import BanksEthiopiaStore from "../store";
  import { countries } from "../assets/AllCountry";

  const dispatch = createEventDispatcher();
  export let current_active;
  let validation = false;
  let createAccount;

  const residence = [
    "Own",
    "Rent",
    "Living with Parents",
    "Living with Relatives",
    "Other",
  ];
  const employment = [
    "Employed",
    "Business Owner",
    "Self-Employed",
    "Unemployed",
    "Student",
    "Other",
  ];

  const formValue = {
    form_of_residence: "Rent",
    monthly_cost: 1000,
    employment_status: "Employed",
    monthly_income: 2000,
    currency: "USD",
    country: "United States of America",
    initial_deposit: 30000,
  };

  BanksEthiopiaStore.subscribe((value) => {
    createAccount = value.service_type;
    formValue.form_of_residence = value.form_of_residence
      ? value.form_of_residence
      : formValue.form_of_residence;
    formValue.monthly_cost = value.monthly_cost ? value.monthly_cost : 1000;
    formValue.employment_status = value.employment_status
      ? value.employment_status
      : formValue.employment_status;
    formValue.monthly_income = value.monthly_income
      ? value.monthly_income
      : 2000;
    formValue.currency = value.currency ? value.currency : "USD";
    formValue.country = value.country
      ? value.country
      : "United States of America";
    formValue.initial_deposit = value.initial_deposit
      ? value.initial_deposit
      : 30000;
  });
  const errors = {
    form_of_residence: "",
    monthly_cost: "",
    employment_status: "",
    monthly_income: "",
  };
  // interlate country with its crosponding currency
  const giveSelection = (e) => {
    let country = countries.find((item) => item.name == e.target.value);
    let currency = country.currency.code;
    formValue.country = e.target.value;

    if (
      currency === "USD" ||
      currency === "EUR" ||
      currency === "GBP" ||
      currency === "ETB"
    ) {
      formValue.currency = currency;
    } else {
      formValue.currency = "USD";
    }
  };
  // render options
  const renderOptions = () => {
    let SELECT = document.getElementById("select");
    countries.forEach((item) => {
      let OPT = document.createElement("OPTION");
      OPT.setAttribute("value", item.name);
      OPT.appendChild(document.createTextNode(item.name));
      SELECT.appendChild(OPT);
    });

    SELECT.onchange = function (e) {
      giveSelection(e);
    };
  };
  const handleClick = () => {
    validation = true;
    if (formValue.form_of_residence === "") {
      errors.form_of_residence = "Form Of Residence is required";
      validation = false;
    } else {
      errors.form_of_residence = "";
    }
    if (
      formValue.monthly_cost === "" ||
      formValue.monthly_cost < 0 ||
      formValue.monthly_cost === 0
    ) {
      if (formValue.monthly_cost < 0) {
        errors.monthly_cost = "Monthly cost should be more 0";
      } else if (formValue.monthly_cost === 0) {
        errors.monthly_cost = "Monthly cost should be more 0";
      } else {
        errors.monthly_cost = "Monthly cost is required";
      }
      // errors.monthly_cost = "Monthly cost is required";
      validation = false;
    } else {
      errors.monthly_cost = "";
    }
    if (formValue.employment_status === "") {
      errors.employment_status = "Employement Status is required";
      validation = false;
    } else {
      errors.employment_status = "";
    }
    if (
      formValue.monthly_income === "" ||
      formValue.monthly_income < 0 ||
      formValue.monthly_income === 0
    ) {
      if (formValue.monthly_income < 0) {
        errors.monthly_income = "Monthly income should be more 0";
      } else if (formValue.monthly_income === 0) {
        errors.monthly_income = "Monthly income should be more 0";
      } else {
        errors.monthly_income = "Monthly income is required";
      }
      validation = false;
    } else {
      errors.monthly_income = "";
    }

    if (validation === false) {
      return;
    }

    BanksEthiopiaStore.update((value) => {
      value.form_of_residence = formValue.form_of_residence;
      value.employment_status = formValue.employment_status;
      value.currency = formValue.currency;
      if (createAccount == "Create Account") {
        value.country = formValue.country;
        value.initial_deposit = formValue.initial_deposit;
      } else {
        value.monthly_cost = formValue.monthly_cost;
        value.monthly_income = formValue.monthly_income;
        value.initial_deposit = "";
      }
      return value;
    });

    if (
      formValue.employment_status == "Unemployed" ||
      formValue.employment_status == "Student" ||
      formValue.employment_status == "Other"
    ) {
      current_active = current_active + 2;
    } else {
      current_active = current_active + 1;
    }
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
  };
  const handleBack = () => {
    current_active = current_active - 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
  };
</script>

<div>
  <div class="progerss-card-header">
    <div class="circle-wrap">
      <div class="circle">
        <div class="mask half">
          <div class="fill" />
        </div>
        <div class="mask full">
          <div class="fill" />
        </div>
        <div class="inside-circle">
          {#if createAccount == "Create Account"}
            2/4
          {:else}
            3/5
          {/if}
        </div>
      </div>
    </div>
    <Header title={"Housing & Work"} next={"Housing & Work"} />
  </div>
  <hr />
  <div class="housing-work-container">
    <label for="form_of_employment">Form Of Residence</label>
    <select
      name="residence"
      id="residence"
      bind:value={formValue.form_of_residence}
    >
      {#each residence as res}
        <option value={res}>{res}</option>
      {/each}
    </select>
    <p class="error">{errors.form_of_residence}</p>
    {#if createAccount === "Create Account"}
      <label for="form_of_employment">Country</label>
      <select name="country" id="select" on:focus={() => renderOptions()}>
        <option value="" selected disabled>Select Country</option>
      </select>
    {/if}
    <label for="monthly_cost">
      {#if createAccount === "Create Account"}
        Your Initial Deposit
      {:else}
        Your Share of the monthly cost
      {/if}
    </label>
    <div class="monthly-cost-container">
      {#if createAccount === "Create Account"}
        <input
          type="number"
          name="monthly_cost"
          id="monthly_cost"
          bind:value={formValue.initial_deposit}
        />
      {:else}
        <input
          type="number"
          name="monthly_cost"
          id="monthly_cost"
          bind:value={formValue.monthly_cost}
        />
      {/if}
      <span
        >{formValue.currency}
        {#if createAccount != "Create Account"}/Month{/if}</span
      >
    </div>
    <p class="error">{errors.monthly_cost}</p>
    <label for="form_of_employment">Form Of Employment</label>
    <select
      name="form_of_employment"
      id="form_of_employment"
      bind:value={formValue.employment_status}
    >
      {#each employment as emp}
        <option value={emp}>{emp}</option>
      {/each}
    </select>
    <p class="error">{errors.employment_status}</p>
    {#if createAccount != "Create Account"}
      <label for="monthly_income">Monthly Income Before Taxes</label>
      <div class="monthly-cost-container">
        <input
          type="number"
          name="monthly_income"
          id="monthly_income"
          bind:value={formValue.monthly_income}
        />
        <span>{formValue.currency} /Month</span>
      </div>
      <p class="error">{errors.monthly_income}</p>
    {/if}
  </div>
  <div class="step-button">
    <Button on:handleClick={handleBack} className={"prev"} width={35}>
      <i class="fa fa-arrow-left" />
      <span> Back </span>
    </Button>
    <Button on:handleClick={handleClick} className={"next"} width={80}>
      <span>Continue</span>
      <i class="fa fa-arrow-right" />
    </Button>
  </div>
</div>

<style>
  .circle-wrap {
    width: 64px;
    height: 64px;
    background: #e7f3e9;
    border-radius: 50%;
    border: 0px solid #cdcbd0;
  }
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
  }

  .mask .fill {
    clip: rect(0px, 32px, 64px, 0px);
    background-color: #00ff29;
  }

  /*  */
  .circle-wrap .circle .mask {
    clip: rect(0px, 64px, 64px, 32px);
  }

  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 0.5s;
    transform: rotate(135deg);
  }

  @keyframes fill {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }

  .circle-wrap .inside-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    line-height: 28px;
    text-align: center;
    margin-top: 7px;
    margin-left: 7px;
    color: #1c274c;
    position: absolute;
    z-index: 100;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* housing-work-container */
  .housing-work-container input,
  select {
    width: 100%;
    height: 48px;
    border: 1px solid #cdcbd0;
    border-radius: 4px;
    padding: 0 15px;
    margin-bottom: 20px;
    background-color: inherit;
  }
  .housing-work-container label {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #1c274c;
  }
  .monthly-cost-container {
    display: flex;
  }
  .monthly-cost-container input {
    border-radius: 4px 0px 0px 4px;
  }
  .monthly-cost-container span {
    height: 48px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1c274c;
    border: 0.5px solid rgba(28, 39, 76, 0.4);
    border-radius: 0px 4px 4px 0px;
    color: #fff;
  }
  .error {
    color: #d91b42;
    font-size: 12px;
    margin-top: -10px;
  }
</style>
