<script>
  import { createEventDispatcher } from "svelte";
  import Button from "../shared/Button.svelte";
  import Header from "../shared/Header.svelte";
  import BanksEthiopiaStore from "../store";
  import { validateString } from "../utils/CheckValidity";
  import { ENV_OBJECT } from "../utils/env";
  import axios from "axios";
  
  const dispatch = createEventDispatcher();
  export let current_active;
  let validation = false;
  let createAccount;
  let recaptcha_token="";

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formValue = {
    year: 2022,
    month: "January",
    company_name: "",
    employment_status: "Business Owner",
  };

  BanksEthiopiaStore.subscribe((value) => {
    createAccount = value.service_type;
    formValue.year = value.year || 2022;
    formValue.month = value.month || "January";
    formValue.company_name = value.company_name || "";
    formValue.employment_status = value.employment_status || "Business Owner";
  });
  const errors = {
    year: "",
    month: "",
    company_name: "",
  };
  const handleClick = async () => {
    validation = true;
    if (formValue.year === "") {
      errors.year = "Year is required";
      validation = false;
    } else {
      errors.year = "";
    }
    if (formValue.company_name === "") {
      errors.company_name = "Company Name is required";
      validation = false;
    } else {
      if (!validateString(formValue.company_name)) {
        errors.company_name = "Company Name is not valid";
        validation = false;
      } else {
        errors.company_name = "";
      }
    }
    if (!validation) {
      return;
    }
    if(!recaptcha_token){
      return;
    }
    BanksEthiopiaStore.update((value) => {
      value.year = formValue.year;
      value.month = formValue.month;
      value.company_name = formValue.company_name;
      value.recaptcha_token = recaptcha_token;
      value.isDiaspora = false
      return value;
    });
    current_active = current_active + 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
  };

  const handleBack = () => {
    current_active = current_active - 1;
    let currentValue = current_active;
    dispatch("steps", { currentValue, formValue });
  };


  grecaptcha.ready(() => {
    let submit_btn = document.querySelector(".next");
    submit_btn.style.cursor = "not-allowed";
    submit_btn.style.pointerEvents = "none";
    submit_btn.style.opacity = "0.5";
    submit_btn.style.backgroundColor = "#ccc";
    submit_btn.style.color = "#fff";
    grecaptcha.render("recaptcha", {
      sitekey: ENV_OBJECT.SITE_KEY,
      callback: function (token) {
        submit_btn.style.cursor = "pointer";
        submit_btn.style.pointerEvents = "auto";
        submit_btn.style.opacity = "1";
        submit_btn.style.backgroundColor = "#2f5af3";
        submit_btn.style.color = "#fff";
        recaptcha_token = token;

      },
    });
  });
</script>

<div>
  <div class="progerss-card-header">
    <div class="circle-wrap">
      <div class="circle">
        <div class="mask half">
          <div class="fill" />
        </div>
        <div class="mask full">
          <div class="fill" />
        </div>
        <div class="inside-circle">
          {#if createAccount == "Create Account"}
            4/4
          {:else}
            5/5
          {/if}
        </div>
      </div>
    </div>
    <Header title={"Housing & Work"} next={"Finish"} />
  </div>
  <hr />
  <div class="complete-container">
    <label for="hired">
      {#if formValue.employment_status == "Employed"} Hired {/if} Since</label
    >
    <div class="year-month">
      <input type="number" name="year" id="year" bind:value={formValue.year} />
      <select name="month" id="month" bind:value={formValue.month}>
        {#each months as month}
          <option value={month}>{month}</option>
        {/each}
      </select>
    </div>
    <p class="error">{errors.year}</p>

    <label for="company_name">Company Name</label>
    <input
      type="text"
      name="company_name"
      id="company_name"
      bind:value={formValue.company_name}
    />
    <p class="error">{errors.company_name}</p>
    <br />
    <div id="recaptcha" />
    <br />
    <hr />
    <p class="t-c">
      By submitting the application, you agree user agreement, personal data
      policy and that BanksEthiopia uses my data to improve my customer
      experience (read more here) .
    </p>
  </div>
  <div class="step-button">
    <Button on:handleClick={handleBack} className={"prev"} width={35}>
      <i class="fa fa-arrow-left" />
      <span> Back </span>
    </Button>
    <Button
      on:handleClick={handleClick}
      className={"next"}
      width={80}
      disabled={"disabled"}
    >
      <span>Continue</span>
      <i class="fa fa-arrow-right" />
    </Button>
  </div>
</div>

<style>
  .circle-wrap {
    width: 64px;
    height: 64px;
    background: #e7f3e9;
    border-radius: 50%;
    border: 0px solid #cdcbd0;
  }
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
  }

  .mask .fill {
    clip: rect(0px, 32px, 64px, 0px);
    background-color: #00ff29;
  }

  /*  */
  .circle-wrap .circle .mask {
    clip: rect(0px, 64px, 64px, 32px);
  }

  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 0.5s;
    transform: rotate(180deg);
  }

  @keyframes fill {
    0% {
      transform: rotate(135deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  .circle-wrap .inside-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    line-height: 28px;
    text-align: center;
    margin-top: 7px;
    margin-left: 7px;
    color: #1c274c;
    position: absolute;
    z-index: 100;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .complete-container input,
  select {
    width: 100%;
    height: 48px;
    border: 1px solid #cdcbd0;
    border-radius: 4px;
    padding: 0 15px;
    margin-bottom: 20px;
    background-color: inherit;
  }
  .complete-container label {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #1c274c;
  }
  .year-month {
    display: flex;
    justify-content: space-between;
  }
  .year-month input,
  .year-month select {
    width: 45%;
  }
  .error {
    color: #d91b42;
    font-size: 12px;
    margin-top: -10px;
  }
  .t-c {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: rgba(28, 39, 76, 0.8);
  }
</style>
